.dashboard-container {
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    padding: 25px;
    width: 100%;
  }
  
  .dashboard-title {
    margin-bottom: 20px;
  }
  
  .dashboard-title-text {
    margin: 0;
    text-transform: uppercase;
  }
  
  
  
  .dashboard-stats-container {
    display: flex;
    flex-direction: row;
  }
  
  .dashboard-total-container {
    border-right: 1px solid #e0e0e0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 20px;
    padding-right: 20px;
  }
  
  .dashboard-total {
    font-size: 56px;
    font-weight: bold;
    margin: 0;
  }
  
  .dashboard-total-label {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;
  }